import _ from 'lodash-es'
export default {
  formatSlides(d) {
    return _.map(d.articles, (v) => {
      return {
        title: v.title,
        url: v.image,
        post_url: v.url,
      }
    })
  },
}
