import Base from '../../base'
import axios from 'axios'
import _ from 'lodash-es'

export default class Default extends Base {
  init() {
    //Bail unless we are on product page
    if (this.d.template.indexOf('collection') == -1 && this.d.template.indexOf('search') == -1) return

    this.bus.$on('vue/loaded', (d) => {
      this.infinite()
    })
    // this.bus.$on('vue/loaded', (d) => {this.initCollection();});
    // this.bus.$on('vue/loaded', (d) => {this.getProducts();});
    this.bus.$on('vue/loaded', (d) => {
      this.filterOptions()
    })
    this.bus.$on('window/scroll', (d) => {
      this.getStickyEnd()
    })
    this.bus.$on('window/resize', (d) => {
      this.getStickyEnd()
    })
  }

  initCollection() {
    this.s.products.data = this.d.products

    if (this.s.products.data.length < this.d.products_total) this.getProducts(2)
  }

  getProducts(pageNum = 1) {
    const products = this.s.products
    // '/collections/all/products.json?page=4'
    // Set a 'loading' state so the function doesn't call itself if it doesn't need to
    axios
      .get(`/collections/${this.d.collection_handle}?view=json&page=${pageNum}`)
      .then((response) => {
        products.data = products.data.concat(response.data)
      })
      .catch((e) => console.log('error getting products', e))

    if (products.data.length < this.d.products_total) this.getProducts(pageNum++)
  }

  filterOptions() {
    const options = this.d.products.reduce(
      (acc, product) =>
        _.merge(
          acc,
          product.variants
            .filter((variant) => variant.available)
            .reduce((acc, variant, index) => ((acc[variant.title] = index), acc), {})
        ),
      {}
    )
    // Options are an object with indexes in case we're sorting by sizing metric
    const optionsArray = Object.keys(options)
    this.s.products.filterList = optionsArray
    this.s.products.filters = optionsArray.reduce((acc, curr) => ((acc[curr] = false), acc), {})
  }

  infinite() {
    this._total_products = this.d.products_total
    this._per_page = this.d.products_page_size
    this._remaining_pages = _.range(2, Math.ceil(this._total_products / this._per_page) + 1)
    this._can_load = true

    //Watch for hitting bottom
    const w = this.s.window
    this.bus.$on('window/scroll', (d) => {
      if (this._remaining_pages.length == 0) return
      if (w.document.height.current == 0) return

      const trigger = w.scroll.top.current + w.resize.height.current + 200 > w.document.height.current
      if (trigger && this._can_load == true) {
        const page = this._remaining_pages.shift()
        this.loadProducts(page)
      }
    })
  }

  loadProducts(page) {
    this._can_load = false

    const products_type = this.d.products_type

    if (products_type == 'collection') {
      const collection_handle = this.d.collection_handle

      //Start loading in products
      this.u.shopify.products.collection({
        handle: collection_handle,
        data: {
          page: page,
        },
        callback: (d) => {
          this.d.products = this.d.products.concat(d)

          setTimeout(() => {
            this._can_load = true
          }, 1000)
        },
      })
    }

    if (products_type == 'search') {
      //Start loading in products
      this.u.shopify.products.search({
        data: {
          q: this.d.search_terms,
          page: page,
        },
        callback: (d) => {
          this.d.products = this.d.products.concat(d)

          setTimeout(() => {
            this._can_load = true
          }, 1000)
        },
      })
    }
  }

  getStickyEnd() {}
}
