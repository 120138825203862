export default {
  hasItems: (data) => {
    return data.cart.items.length > 0
  },
  showProductTitle: (title) => {
    return title.replace(/\([\s\S]*\)/gi, '<span class="variant">' + '$&' + '</span>')
  },
  showItemCount: (data) => {
    return data.cart.item_count
  },
  showItemPrice: (s, v, li) => {
    return v.currency.showMoney(s, li.price)
  },
  showItemTotal: (s, v, li) => {
    return v.currency.showMoney(s, li.line_price)
  },
}
