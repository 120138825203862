import Base from '../../base'
import _ from 'lodash-es'

import Preload from '../../utilities/preload'

export default class Default extends Base {
  init() {
    if (this.d.template.indexOf('article-lookbooks') == -1) return
    //Bail unless we are on product page

    //Preload the images
    this.initCounter()

    this.bus.$on('vue/loaded', () => this.initCounter())
    this.bus.$on('window/resize', () => {
      setTimeout(() => this.initCounter(), 100)
    })
  }

  initCounter() {
    const content = document.getElementById('content')
    if (content) {
      const images = content.getElementsByTagName('img')
      const urls = images.map((v) => v.getAttribute('src'))

      new Preload.images({
        src: urls,
        callback: () => {
          this.startCounter()
        },
      })
    }
  }

  startCounter() {
    //Get images again
    const content = document.getElementById('content')
    if (content) {
      const images = content.getElementsByTagName('img')
      this.s.lookbook.images = _.map(images, (v, idx) => {
        const rect = v.getBoundingClientRect()

        return {
          idx: idx,
          top: rect.top + this.s.window.scroll.top.current,
          height: rect.height,
        }
      })
    }
  }
}
