import Base from '../../base'

export default class Default extends Base {
  init() {
    if (this.d.template.indexOf('index') == -1) return

    this.media = window.matchMedia('(max-width: 800px)')

    this.bus.$on('vue/loaded', () => this.setState())
    this.bus.$on('vue/loaded', () => this.preload())
    this.bus.$on('vue/loaded', () => this.slideShow())
  }

  setState() {
    this.s.index.collections = this.d.index.collections
  }

  preload() {
    const images = this.d.index.collections.map((col) => col.img)
    let loaded = 0
    images.forEach((img) => {
      const image = new Image()
      image.onload = function () {
        loaded++
        if (loaded === images.length) this.s.index.loaded = true
      }.bind(this)
      image.src = img
    })
  }

  slideShow() {
    if (!this.media.matches) return
    const limit = document.querySelectorAll('.block').length - 1
    const tick = 5000
    const indexState = this.s.index

    let timeout = setTimeout(function interval() {
      if (!indexState.loaded) return
      indexState.currentBlock < limit ? indexState.currentBlock++ : (indexState.currentBlock = 0)
      timeout = setTimeout(interval, tick)
    }, tick)
  }
}
