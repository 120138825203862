import currency_helpers from './currency'

export default {
  selectOption(state, value, optPosition) {
    state.product.selected_options[`option${optPosition}`] = value

    this.selectVariant(state)
  },

  selectVariant(state) {
    const options = state.product.selected_options
    // Should really just set up babel/polyfill so I can use Array.find
    const variant = state.product.data.variants.filter((variant) =>
      variant.options.every((opt, i) => options[`option${i + 1}`] === opt)
    )[0]

    state.product.selected_variant = variant || null
  },

  addQty: (state) => {
    const quantity = state.product.selected_quantity
    state.product.selected_quantity = quantity + 1 > 10 ? 10 : quantity + 1
  },
  subQty: (state) => {
    const quantity = state.product.selected_quantity
    state.product.selected_quantity = quantity - 1 < 1 ? 1 : quantity - 1
  },
  toggleOptionSelect: (state, optionType) => {
    state.product.selection_group = state.product.selection_group === optionType ? '' : optionType
  },
  disableOptionSelect: (state) => {
    state.product.selection_group = ''
  },
  toggleInfo: (state, section) => {
    state.product.info = state.product.info === section ? '' : section
  },
}
