import _ from 'lodash-es'
import Vue from 'vue'

export default class Default {
  constructor(_main) {
    Vue.component('Slideshow', {
      props: ['slides'],
      data: function () {
        return {
          width: 0,
          selected: 0,
        }
      },
      mounted: function () {
        // Preload slides
        const preload = _main.utils.preload
        const images = _.map(this.slides, (v) => {
          return v.url
        })
        preload.files({
          src: images,
          callback: () => {},
        })

        // Set width
        this.width = this.$el.getBoundingClientRect().width
        window.addEventListener(
          'resize',
          _.debounce(() => {
            this.width = this.$el.getBoundingClientRect().width
          }, 250)
        )
      },
      methods: {
        nextSlide: function () {
          const select = this.selected + 1 >= this.slides.length ? 0 : this.selected + 1
          this.setSlide(select)
        },
        prevSlide: function () {
          const select = this.selected - 1 < 0 ? this.slides.length - 1 : this.selected - 1
          this.setSlide(select)
        },
        setSlide: function (slide_idx) {
          this.selected = slide_idx
        },
        showImage: function (url, format) {
          // Format dimensions
          const dimensions = '800x1200_crop_center'
          const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
          url = [url.slice(0, dot_pos), `_${dimensions}`, url.slice(dot_pos)].join('')
          return url
        },
        showSlideStyle: function (slide, slide_idx, selected) {
          const style = {
            'background-image': `url(${slide.url})`,
            transform: `translateX(${(slide_idx - selected) * 100}%)`,
          }
          return style
        },
        showHangingSlideStyle: function (slides, selected, start_or_end = 'start') {
          let hanging_idx
          let offset

          if (start_or_end == 'start') {
            offset = -100
            hanging_idx = selected - 1 >= 0 ? selected - 1 : slides.length - 1
          } else {
            offset = 100
            hanging_idx = selected + 1 <= slides.length - 1 ? selected + 1 : 0
          }

          const hanging_slide = slides[hanging_idx]
          const style = {
            'background-image': `url(${hanging_slide.url})`,
            transform: `translateX(${offset}%)`,
          }
          return style
        },
      },
      template: `
        <div class="slideshow" :class="{ 'slideshow-single': slides.length === 1 }">
          <div class="slides">
            <div class="slide" v-bind:style="showHangingSlideStyle(slides,selected, 'start')"></div>
            <div class="slide" v-for="(slide, slide_idx) in slides" v-bind:style="showSlideStyle(slide,slide_idx,selected)"></div>
            <div class="slide" v-bind:style="showHangingSlideStyle(slides,selected, 'end')"></div>
            <button class="nav-button prev" @click="prevSlide"></button>
            <button class="nav-button next" @click="nextSlide"></button>
          </div>
          <div class="pager">
            <nav class="page">
              <ul>
                <li v-for="(slide, slide_idx) in slides" v-on:click="setSlide(slide_idx)" v-bind:class="{active: slide_idx == selected}">
                  <span></span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      `,
    })
  }
}
