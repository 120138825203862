import _ from 'lodash-es'
import $ from 'jquery'
import Vue from 'vue'
import Hammer from 'hammerjs'
export default class Default {
  constructor(_main) {
    Vue.component('SlideshowBlog', {
      props: ['slides'],
      data: function () {
        return {
          pan_offset: 0,
          width: 0,
          selected: 0,
          height: 0,
        }
      },
      mounted: function () {
        //Preload slides
        const preload = _main.utils.preload
        const images = _.map(this.slides, (v) => {
          return v.url
        })
        preload.files({
          src: images,
          callback: () => this.updateDims(),
        })

        this.updateDims()
        window.addEventListener('resize', () => this.updateDims())
        setTimeout(() => this.updateDims(), 1000)

        //Handle swiping
        const $touch_els = this.$el.querySelectorAll('.touch')
        _.each($touch_els, ($touch_el) => {
          const hm = new Hammer($touch_el)
          hm.on('panstart', (ev) => {
            this.panStart(ev)
          })
          hm.on('panmove', (ev) => {
            this.panMove(ev)
          })
          hm.on('panend', (ev) => {
            this.panEnd(ev)
          })
        })
      },
      methods: {
        updateDims: function () {
          this.width = $(this.$el).find('.slides').width()
          this.height = $(this.$el).find('.slide').height()
        },
        panStart: function (ev) {
          // console.log(ev.distance);
          this.pan_offset = 0
        },
        panMove: function (ev) {
          //Max drag as ratio
          const max = 0.6

          //Convert delta X to an -1 - 1 number
          let ratio = ev.deltaX / this.width

          //Constrain the ratio to -0.5 to 0.5;
          if (ratio < -max) ratio = -max
          if (ratio > max) ratio = max

          //Easing should slow the further you drag
          const ease = function (t) {
            return t * (2 - t)
          }
          const abs = Math.abs(ratio) * (1 / max) //ease(Math.abs(ratio) * 2);
          ratio = ratio > 0 ? abs / (1 / max) : -abs / (1 / max)

          //Convert back from ratio to a number
          const offset = ratio * this.width

          this.pan_offset = offset

          //Take over scroll if you have gone too far?
        },
        panEnd: function (ev) {
          //If we have panned further than X, pan to next slide and set selected slide
          const swipe_distance = 50 //this.width / 6; //Lets use what feels normal to the finger
          if (Math.abs(this.pan_offset) > swipe_distance) {
            if (this.pan_offset < 0) {
              this.nextSlide()
            } else {
              this.prevSlide()
            }
          } else {
            this.resetSlide()
          }
        },
        nextSlide: function () {
          // console.log('nextSlide', this.selected, this.slides.length);
          //Difference between current offset and width
          const start = this.pan_offset
          const end = -this.width
          this.animateOffset(start, end, () => {
            //Set slide and revert pan offset
            this.pan_offset = 0
            const select = this.selected + 1 >= this.slides.length ? 0 : this.selected + 1
            this.selected = select
          })
        },
        prevSlide: function () {
          // console.log('prevSlide', this.selected, this.slides.length);
          //Difference between current offset and width
          const start = this.pan_offset
          const end = this.width
          this.animateOffset(start, end, () => {
            //Set slide and revert pan offset
            this.pan_offset = 0
            const select = this.selected - 1 < 0 ? this.slides.length - 1 : this.selected - 1
            this.selected = select
          })
        },
        resetSlide: function () {
          //Smoothly go back to 0
          const start = this.pan_offset
          const end = 0
          this.animateOffset(start, end, () => {
            this.pan_offset = 0
          })
        },

        setSlide: function (slide_idx) {
          //Animate from current slide to whichever slide we are going to
          const start = this.pan_offset
          const end = (this.selected - slide_idx) * this.width + this.pan_offset

          this.animateOffset(start, end, () => {
            this.selected = slide_idx
            this.pan_offset = 0
          })
        },
        animateOffset: function (start, end, cb) {
          //Convert offset to a sensible number of frames
          const frame_total = 20
          let frames = frame_total
          let active = true

          //Easing function
          const ease = function (t) {
            return t * (2 - t)
          }

          //Get distance as ratio
          const difference = end - start

          //Animate from start to end duraion frames
          const loop = (now) => {
            frames -= 1
            if (!!active) {
              //Get frames as ratio
              const animation_ratio = ease((frame_total - frames) / frame_total)
              const position = start + animation_ratio * difference

              this.pan_offset = position

              if (frames <= 0) {
                active = false
                cb()
              }

              requestAnimationFrame(loop)
            }
          }

          requestAnimationFrame(loop)
        },
        getImageUrl: function (url, format = '600x900_crop_center') {
          const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
          url = [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')

          return url
        },
        formatHangingSlide: function (slides, selected, selected_offset = 1) {
          let selected_idx
          const length = slides.length
          const selected1 = selected + selected_offset

          //Get index, wrap around end of array
          if (selected + selected_offset >= length) {
            selected_idx = selected1 - length
          } else if (selected1 < 0) {
            const temp_pos = selected_offset + selected
            selected_idx = length + temp_pos
          } else {
            selected_idx = selected1
          }

          const slide = _.clone(slides[selected_idx])

          return _.merge(slide, {
            is_hanging: true,
            idx: selected_idx,
            selected: selected,
            selected_offset: selected_offset,
          })
        },
        formatSlideTranslateStyle: function (s) {
          let offset
          const slides = this.slides
          const width = this.width
          const pan_offset = this.pan_offset

          if (!s.is_hanging) {
            offset = pan_offset
          } else {
            const pseudo_index = s.selected_offset
            offset = pseudo_index * width + pan_offset
          }

          //Slide translate offset is set by its index * width + its current drag
          return { transform: `translateX(${offset}px)` }
        },
        formatSlide: function (slide, slide_idx, selected) {
          return _.merge(slide, {
            is_hanging: false,
            idx: slide_idx,
            selected: selected,
            start_or_end: -1,
            selected_offset: -1,
          })
        },
        formatSlideImageUrl: function (slide) {
          if (!slide.url) return {}
          return this.getImageUrl(slide.url)
        },
        formatSlides: function () {
          const selected = this.selected

          const formatted_slides = _.map(this.slides, (slide, slide_idx) => {
            return this.formatSlide(slide, slide_idx, selected)
          })

          return [
            this.formatHangingSlide(this.slides, selected, -2),
            this.formatHangingSlide(this.slides, selected, -1),
            formatted_slides[selected],
            this.formatHangingSlide(this.slides, selected, 1),
            this.formatHangingSlide(this.slides, selected, 2),
          ]
        },
        formatSlidesStyle: function () {
          return { height: `${this.height}px` }
        },
      },
      template: `
        <div class="slideshow" >
          <div class="controls">
            <div class="touch touch-left" ref="sliderSwipeLeft"></div>
            <div class="touch touch-right" ref="sliderSwipeRight"></div>
            <div class="control control-right" @click.prevent="nextSlide()"></div>
            <div class="control control-left" @click.prevent="prevSlide()"></div>
          </div>
          <div class="slides" :style="formatSlidesStyle()">
            <div class="slide" v-for="s in formatSlides()":data-index="s.idx":style="formatSlideTranslateStyle(s)" >
              <div class="slide-inner">
                <a :href="s.post_url" class="link"></a>
                <div class="heading">
                  <h3 v-text="s.date"></h3>
                  <h2 v-text="s.title"></h2>
                </div>
                <div class="image">
                  <img :src="formatSlideImageUrl(s)" />
                </div>
                <div class="lookbook">
                  <span>View Lookbook</span>
                  <h2 v-text="s.title"></h2>
                </div>
                <div class="excerpt">
                  <div class="text" v-html="s.excerpt"></div>
                  <div class="view">
                    <span>View Post</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `,
    })
  }
}
