import _ from 'lodash-es'
import $ from 'jquery'
import Vue from 'vue'
export default class Default {
  constructor() {
    Vue.component('Newsletter', {
      props: ['action'],
      data() {
        return {
          email: '',
          message: '',
        }
      },
      methods: {
        submit($evt) {
          $.ajax({
            type: 'post',
            url: JSON.parse(this.action),
            data: {
              EMAIL: this.email,
              Submit: true,
            },
            // cache: false,
            dataType: 'jsonp',
            contentType: 'application/json; charset=utf-8',
            success: (data) => {
              if (data.Status == 400) {
                // Something went wrong, do something to notify the user. maybe alert(data.msg);
                this.message = 'Please enter a valid email address'
              } else {
                if (data.result == 'error') {
                  console.log(data)
                  this.message = data.msg
                } else {
                  // It worked, carry on...
                  this.message = 'Thanks for signing up!'
                  this.email = ''
                }
              }
            },
          })
        },
      },
      template: `
        <form class="form" method="get" v-on:submit.prevent="submit($event)" >
          
          <input class="email" type="text" v-model="email" placeholder="Newsletter" required>
          <button class="button">Sign up</button>
          <div class="message" v-if="message !== ''" v-html="message"></div>
        
        </form>
      `,
    })
  }
}
