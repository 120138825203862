export default {
  data: {},
  info: 'description',
  active_options: '',
  selected_variant: -1,
  selected_quantity: 1,
  selection_group: '',
  selected_options: {},
  selected_slide: 0,
  related: [],
  worn_with: [],
  notify_email: '',
  message: '',
  error: false,
  images: [],
  content_bottom: -1,
  description_bottom: -1,
}
