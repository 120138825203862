import Vue from 'vue'

import store from '../store'

import Utilities from '../utilities'
import Features from '../features'
import OverlayBanner from '../components/overlay/OverlayBanner.vue'

window.app = {
  data: window._data, //Data provided on-page via script tags
  state: {}, //State used on this page
  store: {}, //State shared between pages
  view: {}, //Functions that display state
  mutator: {}, //Functions that mutate state
  event: {}, //Event listeners/publishers
  bus: new Vue(),

  start() {
    this.utils = new Utilities(this)
    this.feat = new Features(this)

    // Create Vue instance
    this.vm = new Vue({
      // delimiters: ["{~","~}"],
      el: '#app',
      components: {
        OverlayBanner,
      },
      data: () => {
        return {
          d: this.data,
          s: this.state,
          v: this.view,
          m: this.mutator,
          ls: this.store, //local storage
          bus: this.bus,
        }
      },
      methods: {
        log: (log) => {
          console.log(log)
        },
        emit: (path, data = {}) => {
          console.log('emit', path)
          this.bus.$emit(path, data)
        },
        instance: () => this,
      },
      store,
    })

    this.bus.$emit('vue/loaded', {})
    console.log('app started')
  },
}

app.start()
