import _ from 'lodash-es'
import fx from 'money'

export default {
  showMoney: (state, cents) => {
    fx.rates = window.Currency.rates
    const base = state.currency.base
    let code = state.currency.code
    let amount = parseInt(cents / 100)

    const original = amount

    let symbol = '$'
    if (code == 'EUR') symbol = '€'
    if (code == 'GBP') symbol = '₤'
    if (code == 'YEN') symbol = '¥'

    if (base !== code) {
      amount = fx.convert(amount, { from: code, to: base }).toFixed(2)
    }

    // amount = amount.replace('.00','');
    code = code === 'NZD' ? '' : code

    return `${symbol}${amount} ${code}`
  },
  showPrice: (state, view, data, code) => {
    const showMoney = view.currency.showMoney
    let formatted_original = false
    const formatted_price = showMoney(state, data.price, code) //.toString().replace('.00', '').replace('.0', '');

    //If original price exists
    if (data.original != null && data.original > data.price)
      formatted_original = showMoney(state, data.original)

    if (formatted_original) {
      return `<span class="sale">${formatted_price.trim()}</span>, <span class="original">${formatted_original}</span>`
    }

    return `${formatted_price}`
  },
  showCurrency: (view, code) => {
    const formatSymbol = view.currency.formatSymbol

    return `${formatSymbol(code)} ${code}`
  },
  formatSymbol: (code) => {
    let symbol = '$'
    if (code == 'EUR') symbol = '€'
    if (code == 'GBP') symbol = '₤'
    if (code == 'YEN') symbol = '¥'
    return symbol
  },
  formatCurrencies: (state, view) => {
    const current_code = state.currency.code
    const showCurrency = view.currency.showCurrency
    const codes = ['NZD', 'AUD', 'USD', 'GBP']

    return _.chain(codes)
      .map((c) => {
        return {
          code: c,
          name: showCurrency(view, c),
        }
      })
      .filter((c) => {
        return c.code !== current_code
      })
      .value()
  },
}
