import _ from 'lodash-es'
export default {
  showClass: (state) => {
    const classes = {}

    return classes
  },
  showSubmenuClass: (state, key) => {
    key = key.replace('submenu-', '')

    // if any same level submenus are open, don't make active
    return state.header.current_submenu == key ? 'active' : ''
  },
  showLinkClass: (state, key) => {
    return state.header.menu == key ? 'active' : ''
  },
  showImage: (state, id) => {
    const image = _.find(state.header.images, (v) => {
      return v.key == id
    })

    if (!image) return {}

    return {
      left: `${image.x + 5}px`,
      top: `${image.y - 10}px`,
      display: image.active ? 'block' : 'none',
    }
  },
  getSearchActive: (state) => {
    return state.header.search_active
  },
}
