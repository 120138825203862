export default {
  showBuyText: (state) => {
    let string = 'Add to Cart'
    const variant = state.product.selected_variant
    const cart_state = state.cart

    //Available state

    if (state.app.has_mouse == false) {
      if (variant != -1 && !variant.available) {
        string = 'Sold Out'
      }
    }

    //Adding to cart
    if (cart_state.current_action == 'adding') string = 'Adding'
    if (cart_state.current_action == 'added') string = 'Added'

    return string
  },
  showBuyMessage: (data, state) => {
    const variant =
      data.product.variants.length === 1 ? data.product.variants[0] : state.product.selected_variant
    const quantity = variant.inventory_quantity
    const management = variant.inventory_management
    const policy = variant.inventory_policy
    let message = state.product.message

    //On sale
    // if(variant != -1) {
    //   if(variant.compare_at_price > variant.price) {
    //     message = "On Sale"
    //   }
    // }

    //If shopify is handling inventory
    if (management == 'shopify') {
      //Preorders allowed and no stock
      // if(policy == 'continue' && quantity < 1) {
      //   message = data.settings.product_preorder;
      // }
      // if(policy == 'deny' && quantity < 1) {
      //   message = data.settings.product_notify;
      // }

      if (quantity === 1) {
        message = `One left`
      }
    }

    //Unavailable
    if (state.app.has_mouse == true) {
      if (variant != -1) {
        if (!variant.available) {
          message = 'Sold Out'
        }
      }
    }

    return message
  },
  getBuyAvailability: (data, state) => {
    const variant = state.product.selected_variant
    const available = variant.available

    return available
  },
}
