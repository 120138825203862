<template>
  <transition name="fade">
    <ObserveResize
      class="overlay-banner"
      :class="`${overlay == 'banner' ? 'overlay-banner--active' : ''} ${
        lightText ? 'overlay-banner--light-text' : ''
      }`"
      :style="`background-color: ${backgroundColor}`"
      @resize="(event) => $emit('resize', event)"
    >
      <div class="overlay-banner__inner">
        <div class="overlay-banner__content">
          <CommonTicker>
            <span v-html="content"></span>
          </CommonTicker>
        </div>
        <button class="overlay-banner__close" @click="close">CLOSE</button>
      </div>
    </ObserveResize>
  </transition>
</template>

<script>
import Vuex from 'vuex'
import CommonTicker from '../common/CommonTicker.vue'
import ObserveResize from '../observe/ObserveResize.vue'

export default {
  computed: {
    ...Vuex.mapState({
      overlay: (state) => state.overlay.current,
      content: (state) => state.data.settings.banner_text,
      backgroundColor: (state) => state.data.settings.banner_background,
      lightText: (state) => state.data.settings.banner_light_text,
    }),
  },
  mounted() {
    const storage = window.localStorage.getItem('banner_overlay')
    const hide_time = !storage ? false : storage

    const oldContent = window.localStorage.getItem('banner_overlay_content')
    const hasChanged = this.content !== oldContent

    // Don't show if shown in last X time
    if (!!hide_time) {
      const current_time = Date.now()
      const days = 1000 * 60 * 60 * 24
      const time_ago = Math.round((current_time - hide_time) / days)
      if (time_ago < 14 && !hasChanged) return
    }

    setTimeout(() => {
      // Don't show if overlay already set
      if (this.overlay !== '') return
      if (this.content == '') return
      // if (window._data.logged_in) return;

      window.localStorage.setItem('banner_overlay_content', this.content)

      //Finally, show
      this.$store.commit('overlay/open', 'banner')
    }, 1000)
  },
  methods: {
    close() {
      window.localStorage.setItem('banner_overlay', Date.now())
      this.$store.commit('overlay/open', '')
    },
  },
  components: {
    ObserveResize,
    CommonTicker,
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.overlay-banner {
  @include sans;
  position: relative;
  opacity: 0;
  margin-left: -20px;
  margin-right: -20px;
  transition: all 0.4s ease;
  overflow: hidden;
  z-index: 1005;
  max-height: 0;
  pointer-events: auto;
  color: $dark;

  &--light-text {
    color: $light;
  }

  &--active {
    opacity: 1;
    max-height: 100px;
  }

  a {
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.5;
    }
  }

  &__inner {
    display: grid;
    line-height: 20px;
    padding: 10px 20px;
    grid-template-columns: 1fr 60px;

    @include respond-to(m) {
      padding: 5px 20px;
    }
  }

  &__content {
    overflow: hidden;
    margin-right: 50px;
    span {
      display: contents;
    }
  }

  &__close {
    cursor: pointer;
    display: flex;
    margin: 0;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    color: currentColor;
  }

  @include respond-to(m) {
    margin: 0;
  }
}
</style>
