import _ from 'lodash-es'
export default {
  toggle: (state, key, toggle = -1) => {
    const idx = _.findIndex(state.app.toggles, { key: key })
    const val = toggle == -1 ? true : toggle

    if (idx > -1) {
      state.app.toggles[idx].val = val
    } else {
      state.app.toggles.push({ key: key, val: val })
    }
  },
  toggleFooter: (s) => {
    s.newsletter.active = !s.newsletter.active
    setTimeout(function () {
      if (s.newsletter.active) {
        document.querySelector('#lol').scrollIntoView({ behavior: 'smooth' })
      }
    }, 10)
  },
}
