// import currency from './currency';
import _ from 'lodash-es'

export default {
  showTitle: (p) => {
    return p.title.replace(/\([\s\S]*\)/gi, '<span class="variant">' + '$&' + '</span>')
  },
  showPrice: (s, v, p) => {
    return v.currency.showPrice(s, v, {
      price: p.price,
      original: p.compare_at_price,
    })
  },
  showNote: (p) => {
    let message = false

    const is_low = !!_.find(p.variants, (v) => {
      return (
        v.inventory_management == 'shopify' &&
        v.inventory_policy == 'deny' &&
        v.inventory_quantity <= 1 &&
        v.inventory_quantity > 0
      )
    })

    // if (p.compare_at_price > p.price) message = "On Sale";
    // if (is_low) message = 'Last one';
    if (!p.available) message = 'Sold Out'

    return message ? message : ''
  },
  showMood: (state, view, product) => {
    let image_path = product.images.length > 1 ? product.images[1].src : product.images[0].src

    image_path = view.app.showImage(image_path, view.products.getResize())

    return { 'background-image': `url(${image_path}` }
  },
  getResize: () => {
    return '600x800_crop_center'
  },
  formatProducts: (d) => {
    const products = _.chunk(d.products, 2)
    return products
  },
  showAvailability: (p) => {
    const colours = _.filter(p.variants, (v) => {
      return v.available
    })

    const pluralise = colours && colours.length > 1 ? 'colors' : 'color'

    return colours && colours.length > 1 ? `Available in ${colours.length} ${pluralise}` : ''
  },
  filterProducts(state, products) {
    const filter = state.products.filters
    const filtersActive = state.products.filtersActive

    if (state.products.filtersActive < 1) return products

    const filtered = products.filter(
      (p) => p.variants.filter((v) => v.available && filter[v.title]).length === filtersActive
    )

    state.products.filteredProducts = filtered.length
    return filtered
  },
  showFiltersActive(state) {
    // TODO: Revist this, maybe sort the filters with a key
    return Object.keys(state.products.filterList)
      .map((key) => state.products.filterList[key])
      .filter((size) => state.products.filters[size])
      .join(', ')
  },
  showSizes(sizes) {
    return sizes.filter((size) => size.available)
  },
}
