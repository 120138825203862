import _ from 'lodash-es'
import $ from 'jquery'
import Vue from 'vue'
export default class Default {
  constructor() {
    Vue.component('ArchiveGallery', {
      props: ['article'],
      data() {
        return {
          text: '',
          images: [],
          imagesLoaded: 0,
          loaded: false,
          inline: false,
          imageData: {},
          currentIndex: 0,
        }
      },

      watch: {
        imagesLoaded() {
          if (this.imagesLoaded !== this.images.length) return
          const images = this.$el.querySelectorAll('img')
          this.imageData = [...images].reduce((acc, curr, index) => {
            const offsetSum = acc.reduce((sum, image) => (sum += image.width), 0)
            // acc[index] = {
            //   width: curr.offsetWidth,
            //   offset: acc.totalWidth
            // }
            // acc.totalWidth += curr.offsetWidth + 150;
            return [...acc, { width: curr.offsetWidth, offset: offsetSum }]
          }, [])

          this.loaded = true
        },
      },

      mounted() {
        const html = this.formatHtml(this.article.body_html)
        this.formatContent(html)
        this.images.forEach((img) => {
          const image = new Image()
          image.onload = (e) => this.imagesLoaded++
          image.src = img.src
        })
      },
      methods: {
        formatHtml(html) {
          const div = document.createElement('div')
          div.innerHTML = html.trim()
          return div
        },

        formatContent(htmlString) {
          const html = htmlString
          const imageEls = html.querySelectorAll('img')
          this.images = [...imageEls].map(this.dissectImg)
          this.text = [...html.children]
            .filter((child) => !child.querySelector('img'))
            .reduce((acc, curr) => ((acc += curr.outerHTML || ''), acc), '')
        },

        dissectImg(imgNode) {
          return {
            src: imgNode.src,
            alt: imgNode.alt,
            width: null,
            height: null,
          }
        },

        htmlToElements(html) {
          const template = document.createElement('template')
          template.innerHTML = html
          return template.content
        },

        getZIndex(images, imageIndex) {
          return images.length - imageIndex
        },

        getImgTranslate(imageIndex) {
          if (this.inline) return `translateX(${this.imageData[imageIndex].offset}px)`

          const zeroIndex = imageIndex === 0
          const xPos = zeroIndex ? 0 : this.randomNegative(_.random(10, 150))
          const yPos = zeroIndex ? 0 : this.randomNegative(_.random(10, 80))

          return `translate3d(${xPos}px, ${yPos}px, 0)`
        },

        randomNegative(num) {
          const multiplier = Boolean(_.random(1)) ? 1 : -1
          return num * multiplier
        },

        setSlide(e) {
          if (!this.inline) return
          const innerWidth = window.innerWidth
          const limit = this.images.length - 1
          const forward = e.clientX > innerWidth / 2
          const imagesEl = this.$el.querySelector('.images')

          if (forward && this.currentIndex < limit) this.currentIndex++
          if (!forward && this.currentIndex > 0) this.currentIndex--

          imagesEl.style.transform = `translate3d(-${this.imageData[this.currentIndex].offset}px, 0, 0)`
        },

        onClick() {
          // $nextTick(() => inline = true)
          this.inline = true
        },
      },
      template: /* html */ `
        <div 
          class="gallery"
          @click="setSlide">
          <div 
            class="images"
            :class="{ cluster: !inline }"
            @click="onClick">
            <div
              class="image"
              v-for="(image, imageIndex) in images"
              :style="{ zIndex: getZIndex(images, imageIndex) }"
            >
              <img 
                :src="image.src"
                :style="{ transform: getImgTranslate(imageIndex)}" />
            </div>
          </div>
          <div class="text">
            <h1>{{ article.title }}</h1>
            <div
              class="credit"
              v-html="text"
            >
            </div>
          </div>
        </div>
      `,
    })
  }
}
