import Slideshow from './components/slideshow'
import SlideshowBlog from './components/slideshow-blog'
import OverlayGallery from './components/overlay-gallery'
import LazyImage from './components/lazy-image'
import Newsletter from './components/newsletter'
import ArchiveGallery from './components/archive-gallery'

export default class Default {
  constructor(main) {
    new Slideshow(main, this)
    new SlideshowBlog(main, this)
    new OverlayGallery(main, this)
    new LazyImage(main, this)
    new Newsletter(main, this)
    new ArchiveGallery(main, this)

    //Use lazy load
    // Vue.use(VueClazyLoad);
  }
}
