
import Vue from 'vue'
import Vuex from 'vuex'

import data from './data';
import overlay from './overlay';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  strict: debug,
  modules: {
    data,
    overlay,
  },
  plugins: [
    
  ],
})
