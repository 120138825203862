// import Base from './base';
import Shopify from './utilities/shopify'
import Window from './utilities/window'
import Mouse from './utilities/helpers/mouse'
import Helpers from './utilities/helpers/helpers'
import Preload from './utilities/preload'

//Vue
import Components from './utilities/components'
import Directives from './utilities/directives'

export default class Default {
  constructor(main) {
    this.shopify = new Shopify(main)
    this.mouse = new Mouse(main)
    this.helpers = new Helpers(main)
    this.window = new Window(main, this)
    this.preload = new Preload(main)
    new Components(main)
    new Directives(main)
  }
}
