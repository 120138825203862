import axios from 'axios'
import Base from '../../base'
import _ from 'lodash-es'
import $ from 'jquery'
import Vue from 'vue'

export default class Default extends Base {
  init() {
    //Bail unless we are on product page
    if (this.d.template.indexOf('product') == -1) return

    //Initialise

    this.bus.$on('vue/loaded', (d) => {
      this.getProduct()
      this.initVariant()
      this.initWornWith()
      this.initRelated('related')
      this.initRelated('with')
      this.bus.$on('window/resize', (d) => {
        this.onResize(d)
      })
    })

    this.bus.$on('product/buy', (d) => {
      this.buy(d)
    })
    this.bus.$on('cart/item/adding', (d) => {
      this.cartItemAdding()
    })
    this.bus.$on('cart/item/added', (d) => {
      this.cartItemAdded()
    })

    //Swatch overlay
    this.bus.$on('overlay/sizing', (s) => {
      this.s.overlay.current = 'sizing'
    })
  }

  getProduct() {
    axios
      .get(`/products/${this.d.product.handle}.js`)
      .then((response) => response.data)
      .then((data) => (this.s.product.data = data))
      .catch((error) => console.log(error))
  }

  initVariant() {
    if (this.d.product.tags.indexOf('hide-options') > -1) {
      const variant = this.d.product.variants[0];
      this.s.product.selected_variant = variant
      this.s.product.selected_options = variant.options.reduce((c,v,k) => {
        c[`option${k+1}`] = v;
        return c;
      },{});

      console.log('selected options', this.s.product.selected_options);
    }
  }

  buy(d) {
    const variant_id = this.s.product.selected_variant.id
    const quantity = this.s.product.selected_quantity

    const isValid = typeof variant_id !== 'undefined'

    if (isValid) {
      this.s.product.error = false
      this.bus.$emit('cart/item/add', { id: variant_id, quantity: quantity })
    } else {
      this.s.product.error = 'Please make a selection'
    }
  }

  cartItemAdding() {
    this.s.cart.current_action = 'adding'
  }

  cartItemAdded() {
    this.s.cart.current_action = 'added'
    this.s.header.cart_active = true
    setTimeout(() => {
      this.s.cart.current_action = ''
    }, 2000)
  }

  onResize() {
    const $content = $('[data-ref="content"]')
    const $images = $('[data-ref="image"]')
    const description_el = document.querySelector('.description')

    this.s.product.images = _.map($images, (v, idx) => {
      const $v = $(v)
      const caption = $v.attr('data-caption')
      const top = $v.offset().top
      const height = $v.height()

      return {
        caption: caption,
        top: top,
        height: height,
        index: idx + 1,
      }
    })

    this.s.product.content_bottom = $content.offset().top + $content.height()
    this.s.product.description_bottom = description_el.offsetTop + description_el.offsetHeight
  }

  initWornWith() {
    // // change ajax to get product via id
    Promise.all(
      this.d.product.tags
        .filter((tag) => tag.match(/^wornwith-/i))
        .slice(0, 3)
        .map((tag) =>
          axios.get(`/products/${tag.replace(/^wornwith-/i, '')}.js`).then((response) => response.data)
        )
    )
      .then((response) => (this.s.product.worn_with = response))
      .catch((error) => console.error('Error fetching wornwith tag products', error.message))
  }

  initRelated(tagName) {
    //Search for related products based on tag
    const tags = this.d.product.tags
    const tag = _.filter(tags, (t) => {
      return t.indexOf(tagName + '-') !== -1
    })[0]

    if (!tag) return //No point searching without tag
    const handle = tag.replace(tagName + '-', '')

    this.u.shopify.products.collection({
      handle: handle,
      callback: (products) => {
        //Filter out the current product, because we don't need it
        const related = []
        if (products.length >= 1) {
          //Remove current product from list
          this.s.product[tagName] = this.formatRelated(products)
        }
      },
    })
  }

  formatRelated(products, include_self = false) {
    return _.chain(products)
      .filter((p) => {
        return include_self ? true : p.id !== this.d.product.id
      })
      .map((p) => {
        p.image_size = this.v.products.getResize(p)
        return p
      })
      .shuffle()
      .slice(0, 4)
      .value()
  }
}
