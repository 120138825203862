import _ from 'lodash-es'
import $ from 'jquery'
import Vue from 'vue'
export default class Default {
  constructor() {
    Vue.component('LazyImage', {
      props: ['queue', 'window', 'src', 'size'],
      data: function () {
        return {
          ready: false,
          loaded: false,
        }
      },
      mounted: function () {
        this.init()
      },
      methods: {
        init: function () {
          this.ready = true
        },
        getSrc: (url, format) => {
          //Split url by '.'
          const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
          // Insert format in string
          return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
        },
        showImage: function (ready) {
          if (!ready) return false
          if (this.loaded) return this.getSrc(this.src, this.size)

          const $el = $(this.$el)
          const queue = this.queue
          const el_top = $el.offset().top
          const scroll_top = this.window.scroll.top.current
          const window_height = this.window.resize.height.current
          const lookahead = scroll_top + 2 * window_height
          const lookbehind = scroll_top - 2 * window_height

          if (el_top < lookahead && el_top > lookbehind) {
            this.loaded = true
            return this.getSrc(this.src, this.size)
          }

          return false
        },
      },
      template: `
        <img v-bind:src="showImage(ready)" v-bind:class="{active:loaded}"/>
      `,
    })
  }
}
