export default {
  setOverlay: (state, overlay = '') => {
    state.overlay.previous = state.overlay.current
    state.overlay.current = overlay

    document.documentElement.style.overflow = !!state.overlay.current ? 'hidden' : ''
  },
  toggleMMenuShop: (state) => {
    state.overlay.mmenu.shopActive = !state.overlay.mmenu.shopActive
  },
  toggleOverlay(state, overlay) {
    const current = overlay !== state.overlay.current ? overlay : ''
    state.overlay.previous = state.overlay.current
    state.overlay.current = current
    document.documentElement.style.overflow = !!state.overlay.current ? 'hidden' : ''
  },
  hideAlert: (state, store) => {
    state.overlay.browser_alert = false
    localStorage.setItem('alertClosed', true)
  },
}
