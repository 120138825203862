import _ from 'lodash-es'
import moment from 'moment'

export default {
  formatSlides(d) {
    return _.map(d.articles, (v) => {
      const date = moment(v.published_at * 1000).format('MM/DD/YY')

      return {
        date: date,
        title: v.title,
        excerpt: v.excerpt,
        url: v.image,
        post_url: v.url,
      }
    })
  },
}
