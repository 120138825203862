import Base from '../base'

import AppE from './event/app'
import AppS from './state/app'
import AppM from './mutator/app'
import AppV from './view/app'

import HeaderE from './event/header'
import HeaderS from './state/header'
import HeaderV from './view/header'
import HeaderM from './mutator/header'

import NewsletterS from './state/newsletter'
// import NewsletterV from './view/newsletter';
import NewsletterM from './mutator/newsletter'

import CurrencyS from './state/currency'
import CurrencyV from './view/currency'
import CurrencyM from './mutator/currency'
import CurrencyE from './event/currency'

import CartS from './state/cart'
import CartV from './view/cart'
import CartM from './mutator/cart'
import CartE from './event/cart'

import AccountS from './state/account'
import AccountV from './view/account'
import AccountM from './mutator/account'
// import AccountE from './event/account';

import ProductS from './state/product'
import ProductV from './view/product'
import ProductM from './mutator/product'
import ProductE from './event/product'

import ProductsS from './state/products'
import ProductsV from './view/products'
import ProductsM from './mutator/products'
import ProductsE from './event/products'

import LookbooksS from './state/lookbooks'
import LookbooksV from './view/lookbooks'
import LookbooksM from './mutator/lookbooks'
import LookbooksE from './event/lookbooks'

import LookbookS from './state/lookbook'
import LookbookV from './view/lookbook'
import LookbookM from './mutator/lookbook'
import LookbookE from './event/lookbook'

import ArticlesV from './view/articles'

import IndexS from './state/index'
import IndexV from './view/index'
import IndexM from './mutator/index'
import IndexE from './event/index'

import OverlayS from './state/overlay'
import OverlayV from './view/overlay'
import OverlayM from './mutator/overlay'
import OverlayE from './event/overlay'

import LoginE from './event/login'
import LoginS from './state/login'
import LoginV from './view/login'
import LoginM from './mutator/login'

export default class Default extends Base {
  init() {
    this.e.app = new AppE(this.b)
    this.s.app = AppS
    this.v.app = AppV
    this.m.app = AppM
    this.s.header = HeaderS
    this.v.header = HeaderV
    this.m.header = HeaderM
    this.e.header = new HeaderE(this.b)
    this.s.currency = CurrencyS
    this.v.currency = CurrencyV
    this.m.currency = CurrencyM
    this.e.currency = new CurrencyE(this.b)
    this.s.newsletter = NewsletterS
    // this.v.newsletter = NewsletterV;
    this.m.newsletter = NewsletterM
    this.s.cart = CartS
    this.v.cart = CartV
    this.m.cart = CartM
    this.e.cart = new CartE(this.b)
    this.s.account = AccountS
    this.v.account = AccountV
    this.m.account = AccountM
    // this.e.account = new AccountE(this.b);
    this.s.product = ProductS
    this.v.product = ProductV
    this.m.product = ProductM
    this.e.product = new ProductE(this.b)
    this.s.products = ProductsS
    this.v.products = ProductsV
    this.m.products = ProductsM
    this.e.products = new ProductsE(this.b)
    this.s.lookbooks = LookbooksS
    this.v.lookbooks = LookbooksV
    this.m.lookbooks = LookbooksM
    this.e.lookbooks = new LookbooksE(this.b)
    this.s.lookbook = LookbookS
    this.v.lookbook = LookbookV
    this.m.lookbook = LookbookM
    this.e.lookbook = new LookbookE(this.b)
    this.v.articles = ArticlesV
    this.s.index = IndexS
    this.v.index = IndexV
    this.m.index = IndexM
    this.e.index = new IndexE(this.b)
    this.s.overlay = OverlayS
    this.v.overlay = OverlayV
    this.m.overlay = OverlayM
    this.e.overlay = new OverlayE(this.b)
    this.s.login = LoginS
    this.v.login = LoginV
    this.m.login = LoginM
    this.e.login = new LoginE(this.b)
  }
}
