import currency from './currency'
import _ from 'lodash-es'

import Options from './product/options'
import Buy from './product/buy'

const Product = {
  showOptionClass(state, optPosition, value) {
    return state.product.selected_options[`option${optPosition}`] === value
  },
  showTotal: (state, view) => {
    const total =
      parseInt(state.product.selected_variant.price) * parseInt(state.product.selected_quantity)

    return view.currency.showPrice(state, view, {
      price: total,
    })
  },
  showPartPay(state, view) {
    const selected = state.product.selected_variant
    const price = selected !== -1 ? selected.available && selected.price : state.product.data.price

    if (!price) return ''

    return `Or 4 payments of ${currency.showPrice(state, view, { price: price / 4 })} with Afterpay`
  },
  isAvailable: (variant) => {
    return !!variant.available
  },
  showTitle: (d, v) => {
    return d.product.title.replace(/\([\s\S]*\)/gi, '<span class="variant">' + '$&' + '</span>')
  },
  showPrice: (d, s, v, p) => {
    const variants = s.product.data.variants
    const variant = p !== -1 ? p : variants ? variants[0] : null

    return variant
      ? v.currency.showPrice(s, v, {
          price: variant.price,
          original: variant.compare_at_price,
        })
      : ''
  },
  showNote: (p) => {
    if (p === -1) return ''
    let message = false

    const is_low = !!_.find(p.variants, (v) => {
      return (
        v.inventory_management == 'shopify' &&
        v.inventory_policy == 'deny' &&
        v.inventory_quantity <= 3 &&
        v.inventory_quantity > 0
      )
    })

    // if (p.compare_at_price > p.price) message = "On Sale";
    if (!p.available) message = 'Sold Out'
    if (is_low) message = 'Low in Stock'

    return message ? message : ''
  },
  hasSalePrice: (state, view, variant) => {
    const price = variant.price
    const original = variant.compare_at_price
    return original != null && original > price
  },
  showNotify: (data, state) => {
    const product = data.product
    const variant = state.product.selected_variant
    const available = variant.available
    const is_notifiable = _.includes(product.tags, 'notifiable')

    return is_notifiable && !available ? true : false
  },
  isNotified: (state) => {
    const get = []
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (a, name, value) {
      get[name] = value
    })

    return !!get['contact_posted']
  },

  showGalleryCaption: (d, s) => {
    const offset_top = s.window.scroll.top.current
    const window_height = s.window.resize.height.current

    const images = _.clone(s.product.images).reverse()

    const image = _.find(images, (v) => {
      const window_half = offset_top + window_height / 2
      // var image_half  = v.top + (v.height/2);
      return window_half > v.top
    })

    if (!image) return ''

    const title = image.caption
    const total = d.product.images.length + 1

    return `${image.index}/${total} ${title}`
  },
  showContentClass: (s) => {
    const content_bottom = s.product.content_bottom
    const window_bottom = s.window.scroll.top.current + s.window.resize.height.current
    const sticky_end = window_bottom > content_bottom
    const sticky_disable = s.product.description_bottom > s.window.resize.height.current

    return {
      'sticky-end': sticky_end,
      'sticky-disable': sticky_disable,
    }
  },
  showSelectionGroup: (s, optionType) => {
    return s.product.selection_group === optionType
  },
  getBuyAvailability: (d, s) => {
    return s.product.selected_variant.available
  },
  formatGallery: (images) => {
    return images.filter((image) => image.alt.indexOf('ways-to-wear') === -1)
  },
  formatSlideshow(s, images, opts_switch = '') {
    return _.chain(images)
      .map((img, idx) => {
        return {
          alt: img.alt,
          url: img.src,
          width: img.width,
          height: img.height,
          class: s.product.selected_slide == idx ? 'active' : '',
        }
      })
      .value()
  },
}

export default _.merge(Product, Buy, Options)
