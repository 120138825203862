import axios from 'axios'
import Base from '../../base'

export default class Default extends Base {
  init() {
    this.bus.$on('vue/loaded', () => this.getCart())
    this.bus.$on('cart/item/add', (d) => this.add(d))
    this.bus.$on('cart/item/remove', (d) => this.remove(d))
    this.bus.$on('cart/item/update', (d) => this.update(d))
  }

  getCart() {
    return axios
      .get('/cart.js')
      .then((response) => (this.d.cart = response.data))
      .catch((error) => console.log(error))
  }

  add(d) {
    const id = d.id
    const quantity = typeof d.quantity !== 'undefined' ? d.quantity : 1

    this.bus.$emit('cart/item/adding')
    axios
      .post('/cart/add.js', {
        id,
        quantity,
      })
      .then(() => (this.s.overlay.current = 'cart'))
      .then(() => this.getCart())
      .then(() => this.bus.$emit('cart/item/added'))
      .catch((error) => console.log(error))
  }

  remove(d) {
    const id = d.id
    this.bus.$emit('cart/item/removing')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: 0,
        },
      },
      callback: (_d) => {
        this.refresh('cart/item/removed')
      },
    })
  }

  update(d) {
    const id = d.id
    const qty = d.quantity
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: qty,
        },
      },
      callback: (d) => {
        this.refresh('cart/item/updated')
      },
    })
  }

  updateAttributes(attributes) {
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        attributes: attributes,
      },
      callback: (d) => {
        this.refresh('cart/item/updated')
      },
    })
  }

  refresh(path) {
    //Refresh the cart contents
    this.u.shopify.cart.get({
      callback: (d) => {
        this.d.cart = d
        this.bus.$emit(path)
      },
    })
  }
}
