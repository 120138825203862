import $ from 'jquery'
export default {
  submit: (state, data, $event) => {
    // var fd = new FormData();
    // fd.append('cm-jyludd-jyludd', state.email);
    // fd.append('Submit', true);

    //Newsletter action example:
    //https://thedrivenewyork.us7.list-manage.com/subscribe/post-json?u=a2e08722d7e089c5e2d76e22a&id=76bcaa9cb6&c=?

    $.ajax({
      type: 'post',
      url: data.newsletter_action,
      data: {
        EMAIL: state.email,
        Submit: true,
      },
      // cache: false,
      dataType: 'jsonp',
      contentType: 'application/json; charset=utf-8',
      success: (data) => {
        if (data.Status == 400) {
          // Something went wrong, do something to notify the user. maybe alert(data.msg);
          state.message = 'Please enter a valid email address'
        } else {
          if (data.result == 'error') {
            state.message = data.msg
          } else {
            // It worked, carry on...
            state.message = 'Thanks for signing up!'
            state.email = ''
          }
        }
      },
    })
  },
}
