import _ from 'lodash-es'
import moment from 'moment'

export default {
  isToggleActive: (state, key) => {
    const toggle = _.find(state.app.toggles, { key: key })
    return !!toggle ? toggle.val : false
  },
  showClass: (data, state, view, instance) => {
    const classes = { loaded: true }

    _.merge(classes, view.app.getOverlayClass(data, state, view, instance))

    _.merge(classes, view.app.getHeaderClass(data, state, view, instance))

    if (!_.isEqual(state.app.classes, classes)) state.app.classes = classes

    return classes
  },
  getOverlayClass: (data, state, view, instance) => {
    const classes = {}
    const overlay = state.overlay.current
    if (overlay !== '') classes[`overlay-${overlay}`] = true

    return classes
  },
  getHeaderClass: (data, state, view, instance) => {
    const classes = {}
    const scroll_top = state.window.scroll.top.current

    if (scroll_top > 10) classes['header-fade'] = true

    return classes
  },
  showImage: (url, format = '600x800_crop_center') => {
    const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1

    return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
  },
  showInnerClass: (state) => {
    return {
      blur: state.overlay.current != '',
    }
  },
  showDate: (timestamp) => {
    const date = moment(timestamp * 1000)

    return date.format('DD/MM/YY')
  },

  formatLocation(stockists_data, location) {
    const locationObject = _.groupBy(stockists_data, location)
    return Object.keys(locationObject).map((l) => ({
      title: l,
      sublocations: locationObject[l],
    }))
  },
}
