<template>
  <div class="ticker">
    <div ref="input" class="ticker__input"><slot></slot></div>
    <div v-for="(banner, key) in getBanners" :key="key" class="ticker__container" v-html="banner"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      html: '',
    }
  },
  computed: {
    getBanners() {
      return new Array(10).fill(this.html)
    },
  },
  mounted() {
    const html = this.$refs.input.innerHTML
    this.html = html
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.ticker {
  overflow: hidden;
  white-space: nowrap;

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }

  &__input {
    display: none;
  }

  &__container {
    display: inline-flex;
    width: auto;
    text-align: center;
    animation: marquee 20s linear infinite;
    overflow: hidden;
    padding-right: 20px;
  }
}
</style>
