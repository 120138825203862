export default {
  showClass: (state, overlay) => {
    const classes = {}

    if (state.overlay.current == overlay) classes['active'] = true

    //Last class
    if (state.overlay.previous == overlay) classes['previous'] = true

    return classes
  },
  showClose: (state) => {
    return state.overlay.current != '' && state.overlay.current != 'newsletter'
  },
  isActive: (state, overlay) => {
    return state.overlay.current === overlay
  },
  showMMenuShopClass: (state) => {
    return state.overlay.mmenu.shopActive ? 'shop-active' : ''
  },
}
