import $ from 'jquery'
export default class Preload {
  constructor(main) {
    this._files = []

    //Create preload div
    if (!$('#preload').is('*')) {
      this.$preload = $('<div id="preload" />').appendTo('body')
    } else {
      this.$preload = $('#preload')
    }

    this.$preload.hide()
  }

  files({ src, callback }) {
    this._files = src

    const file = this._files.shift()
    if (typeof file != 'undefined') {
      this.file({
        src: file,
        callback: (d) => {
          //Go to the next file
          this.files({
            src: this._files,
            callback: callback,
          })
        },
      })
    } else {
      console.log('run callback!')
      this.clear()
      if (typeof callback != 'undefined') callback()
    }
  }

  file({ src, callback }) {
    src = src.replace(/"/g, ' ')
    const type = this.getFileType(src)
    const $p = this.$preload
    const has_cb = typeof callback != 'undefined'

    //Bail if we don't recognise the type
    if (type == false) {
      callback()
      //Handle types
    } else if (type == 'img') {
      const $file = $('<img />').attr('src', src).appendTo($p)

      if (has_cb) {
        $file.on('load', (d) => {
          callback(d)
        })
      }
    } else if (type == 'js') {
      $.getScript(src, (d) => {
        console.log('loaded js')
        if (has_cb) callback(d)
      })
    } else if (type == 'css') {
      const $file = $('<link />').attr('href', src).attr('rel', 'stylesheet').appendTo($p)

      if (has_cb) {
        $file.load((d) => {
          //console.log('loaded css');
          callback(d)
        })
      }
    }
  }

  image({ src, callback }) {
    this.file({ src, callback })
  }

  images({ src, callback }) {
    this.files({ src, callback })
  }

  clear() {
    this._images = []
    $('#preload img').remove()
    $('#preload script').remove()
    $('#preload style').remove()
  }

  getFileType(src) {
    const ext = src.substr(src.lastIndexOf('.') + 1).split('?')[0]
    if (/(js)$/gi.test(ext)) {
      return 'js'
    } else if (/(css)$/gi.test(ext)) {
      return 'css'
    } else if (/(jpg|png|gif)$/gi.test(ext)) {
      return 'img'
    }
  }
}
